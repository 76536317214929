<template>
  <div id="stats-date">
    <StatsAccountDate />
  </div>
</template>

<script>
import StatsAccountDate from '../../../components/stats/account/StatsAccountDate'

export default {
  components: {
    StatsAccountDate
  }
}
</script>
